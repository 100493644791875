<template>
  <div class="newsfeed">
    <div class="newsfeed-choices">
      <button :class="{ activeType: display === 1 }" @click="display = 1">
        {{ $t('newsfeed.news') }}
      </button>
      <button :class="{ activeType: display === 0 }" @click="display = 0">
        {{ $t('newsfeed.blog') }}
      </button>
      <button :class="{ activeType: display === 2 }" @click="display = 2">
        {{ $t('newsfeed.events') }}
      </button>
    </div>

    <BlogReview v-if="display === 0" />
    <News v-else-if="display === 1" />
    <Events v-else />
  </div>
</template>

<script>
import BlogReview from '@/components/modules/newsfeed/blogReview.vue'
import News from '@/components/modules/newsfeed/news.vue'
import Events from '@/components/modules/newsfeed/events.vue'

export default {
  name: 'newsfeed',
  components: {
    BlogReview,
    News,
    Events,
  },
  data() {
    return {
      display: 1,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.newsfeed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .newsfeed-choices {
    margin-top: 20px;

    button {
      background: #2667ff;
      border-radius: 50px;
      border: none;
      color: white;
      margin-right: 8px;
      width: 135px;
      height: 34px;
      font-size: 15px;
      font-weight: 600;
    }

    .activeType {
      background: white !important;
      color: black !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .newsfeed-choices {
    margin: auto;

    button {
      width: 110px !important;
      font-size: 13px !important;
    }
  }
}
</style>
