<template>
  <div v-if="isComputer" class="newsfeed">
    <div class="table">
      <div class="events-options-infos">
        <button class="resetAllButton" @click="resetFilters">
          {{ $t('newsfeed.reset_filters') }}
        </button>
        <p>{{ filteredEvents.length }} {{ filteredEvents.length === 1 || filteredEvents.length === 0 ? $t('newsfeed.events_show_1') : $t('newsfeed.events_show_2') }}</p>
      </div>
      <table class="table-rounded">
        <thead>
          <th>
            <button @click="filterState('showFilterEvent')">
              {{ $t('newsfeed.event_name') }}
              <i
                :class="[
                  'arrow',
                  { right: !showFilterEvent },
                  { bottom: showFilterEvent },
                ]"
              ></i>
            </button>
            <div v-if="showFilterEvent" class="filter">
              <div>
                <el-checkbox class="checkbox" v-model="sortEvent">{{
                  $t('newsfeed.sort_asc')
                }}</el-checkbox>
              </div>
              <div>
                <el-input
                  v-model="searchEvent"
                  :placeholder="$t('newsfeed.search')"
                  :prefix-icon="SearchIcon"
                ></el-input>
              </div>
              <div class="filterButtons">
                <button
                  class="filterButton"
                  @click="
                    reset('searchEvent', 'searchEvent', 'showFilterEvent')
                  "
                >
                  {{ $t('commons.reset') }}
                </button>
                <button class="filterButton" @click="unshow('showFilterEvent')">
                  {{ $t('commons.apply') }}
                </button>
              </div>
            </div>
          </th>

          <th>
            <button @click="filterState('showFilterCountry')">
              {{ $t('newsfeed.country') }}
              <i
                :class="[
                  'arrow',
                  { right: !showFilterCountry },
                  { bottom: showFilterCountry },
                ]"
              ></i>
            </button>
            <div v-if="showFilterCountry" class="filter">
              <div>
                <el-input
                  type="text"
                  v-model="searchCountry"
                  :placeholder="$t('newsfeed.search')"
                  :prefix-icon="SearchIcon"
                />
              </div>
              <el-checkbox-group v-model="tempFilterCountry" class="options">
                <el-checkbox
                  class="checkbox"
                  v-for="(country, index) in getCountries"
                  :key="index"
                  :value="country.toLowerCase()"
                >
                  {{ country }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="filterButtons">
                <button
                  class="filterButton"
                  @click="
                    reset(
                      'filterCountry',
                      'tempFilterCountry',
                      'showFilterCountry'
                    )
                  "
                >
                  {{ $t('commons.reset') }}
                </button>
                <button
                  class="filterButton"
                  @click="
                    applyFilter(
                      'filterCountry',
                      'tempFilterCountry',
                      'showFilterCountry'
                    )
                  "
                >
                  {{ $t('commons.apply') }}
                </button>
              </div>
            </div>
          </th>

          <th>
            <button @click="filterState('showFilterCity')">
              {{ $t('newsfeed.city') }}
              <i
                :class="[
                  'arrow',
                  { right: !showFilterCity },
                  { bottom: showFilterCity },
                ]"
              ></i>
            </button>
            <div v-if="showFilterCity" class="filter">
              <div>
                <el-input
                  type="text"
                  v-model="searchCity"
                  :placeholder="$t('newsfeed.search')"
                  :prefix-icon="SearchIcon"
                />
              </div>
              <el-checkbox-group v-model="tempFilterCity" class="options">
                <el-checkbox
                  class="checkbox"
                  v-for="(city, index) in getCities"
                  :key="index"
                  :value="city.toLowerCase()"
                >
                  {{ city }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="filterButtons">
                <button
                  class="filterButton"
                  @click="
                    reset('filterCity', 'tempFilterCity', 'showFilterCity')
                  "
                >
                  {{ $t('commons.reset') }}
                </button>
                <button
                  class="filterButton"
                  @click="
                    applyFilter(
                      'filterCity',
                      'tempFilterCity',
                      'showFilterCity'
                    )
                  "
                >
                  {{ $t('commons.apply') }}
                </button>
              </div>
            </div>
          </th>

          <th>
            <button @click="filterState('showFilterDate')">
              {{ $t('newsfeed.dates') }}
              <i
                :class="[
                  'arrow',
                  { right: !showFilterDate },
                  { bottom: showFilterDate },
                ]"
              ></i>
            </button>
            <div v-if="showFilterDate" class="filter">
              <el-date-picker
                v-model="dateValue"
                type="daterange"
                unlink-panels
                range-separator="->"
                :start-placeholder="$t('newsfeed.start_date')"
                :end-placeholder="$t('newsfeed.end_date')"
                :disabled-date="disabledDate"
              />
              <div class="filterButtons">
                <button
                  class="filterButton"
                  @click="reset('dateValue', 'dateValue', 'showFilterDate')"
                >
                  {{ $t('commons.reset') }}
                </button>
                <button class="filterButton" @click="unshow('showFilterDate')">
                  {{ $t('commons.apply') }}
                </button>
              </div>
            </div>
          </th>

          <th>
            <button @click="filterState('showFilterMonth')">
              {{ $t('newsfeed.month') }}
              <i
                :class="[
                  'arrow',
                  { right: !showFilterMonth },
                  { bottom: showFilterMonth },
                ]"
              ></i>
            </button>
            <div v-if="showFilterMonth" class="filter">
              <el-checkbox-group class="options" v-model="tempFilterMonth">
                <el-checkbox
                  class="checkbox"
                  v-for="(month, index) in getMonths"
                  :key="index"
                  :value="month.toLowerCase()"
                >
                  {{ month }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="filterButtons">
                <button
                  class="filterButton"
                  @click="
                    reset('filterMonth', 'tempFilterMonth', 'showFilterMonth')
                  "
                >
                  {{ $t('commons.reset') }}
                </button>
                <button
                  class="filterButton"
                  @click="
                    applyFilter(
                      'filterMonth',
                      'tempFilterMonth',
                      'showFilterMonth'
                    )
                  "
                >
                  {{ $t('commons.apply') }}
                </button>
              </div>
            </div>
          </th>

          <th>
            <button @click="filterState('showFilterSpirit')">
              {{ $t('newsfeed.spirit_type') }}
              <i
                :class="[
                  'arrow',
                  { right: !showFilterSpirit },
                  { bottom: showFilterSpirit },
                ]"
              ></i>
            </button>
            <div v-if="showFilterSpirit" class="filter">
              <el-checkbox-group class="options" v-model="tempFilterSpirit">
                <el-checkbox
                  class="checkbox"
                  v-for="(spirit, index) in getSpirit"
                  :key="index"
                  :value="spirit.toLowerCase()"
                >
                  {{ spirit }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="filterButtons">
                <button
                  class="filterButton"
                  @click="
                    reset(
                      'filterSpirit',
                      'tempFilterSpirit',
                      'showFilterSpirit'
                    )
                  "
                >
                  {{ $t('commons.reset') }}
                </button>
                <button
                  class="filterButton"
                  @click="
                    applyFilter(
                      'filterSpirit',
                      'tempFilterSpirit',
                      'showFilterSpirit'
                    )
                  "
                >
                  {{ $t('commons.apply') }}
                </button>
              </div>
            </div>
          </th>
        </thead>
        <tbody>
          <tr v-for="(event, index) in filteredEvents" :key="index">
            <td>
              <a :href="event.url" target="_blank">{{ event.name }}</a>
            </td>
            <td>{{ event.country }}</td>
            <td>{{ event.city }}</td>
            <td>{{ formatDates(event.dates) }}</td>
            <td>{{ event.month }}</td>
            <td>{{ formatSpirit(event.spirit_type_label) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div v-else class="responsive">
    <div class="eventNameSearch">
      <el-input
        v-model="searchEvent"
        placeholder="Search Event"
        :prefix-icon="SearchIcon"
      ></el-input>
    </div>

    <div class="eventFilterResponsive">
      <button @click="filterState('responsiveFilter')">
        {{ $t('newsfeed.events_filters') }}
        <i
          :class="[
            'arrow',
            { right: !responsiveFilter },
            { bottom: responsiveFilter },
          ]"
        ></i>
      </button>
      <div v-if="responsiveFilter">
        <button class="subFilter" @click="filterState('showFilterEvent')">
          {{ $t('newsfeed.event_name') }}
          <i
            :class="[
              'arrow',
              { right: !showFilterEvent },
              { bottom: showFilterEvent },
            ]"
          ></i>
        </button>
        <div v-if="showFilterEvent" class="filter">
          <div>
            <el-checkbox class="checkbox" v-model="sortEvent">{{
              $t('newsfeed.sort_asc')
            }}</el-checkbox>
          </div>
          <div>
            <el-input
              v-model="searchEvent"
              :placeholder="$t('newsfeed.search')"
              :prefix-icon="SearchIcon"
            ></el-input>
          </div>
          <div class="filterButtons">
            <button
              class="filterButton"
              @click="reset('searchEvent', 'searchEvent', 'showFilterEvent')"
            >
              {{ $t('commons.reset') }}
            </button>
            <button class="filterButton" @click="unshow('showFilterEvent')">
              {{ $t('commons.apply') }}
            </button>
          </div>
        </div>

        <button class="subFilter" @click="filterState('showFilterCountry')">
          {{ $t('newsfeed.country') }}
          <i
            :class="[
              'arrow',
              { right: !showFilterCountry },
              { bottom: showFilterCountry },
            ]"
          ></i>
        </button>
        <div v-if="showFilterCountry" class="filter">
          <div>
            <el-input
              type="text"
              v-model="searchCountry"
              :placeholder="$t('newsfeed.search')"
              :prefix-icon="SearchIcon"
            />
          </div>
          <el-checkbox-group v-model="tempFilterCountry" class="options">
            <el-checkbox
              class="checkbox"
              v-for="(country, index) in getCountries"
              :key="index"
              :value="country.toLowerCase()"
            >
              {{ country }}
            </el-checkbox>
          </el-checkbox-group>
          <div class="filterButtons">
            <button
              class="filterButton"
              @click="
                reset('filterCountry', 'tempFilterCountry', 'showFilterCountry')
              "
            >
              {{ $t('commons.reset') }}
            </button>
            <button
              class="filterButton"
              @click="
                applyFilter(
                  'filterCountry',
                  'tempFilterCountry',
                  'showFilterCountry'
                )
              "
            >
              {{ $t('commons.apply') }}
            </button>
          </div>
        </div>

        <button class="subFilter" @click="filterState('showFilterCity')">
          {{ $t('newsfeed.city') }}
          <i
            :class="[
              'arrow',
              { right: !showFilterCity },
              { bottom: showFilterCity },
            ]"
          ></i>
        </button>
        <div v-if="showFilterCity" class="filter">
          <div>
            <el-input
              type="text"
              v-model="searchCity"
              :placeholder="$t('newsfeed.search')"
              :prefix-icon="SearchIcon"
            />
          </div>
          <el-checkbox-group v-model="tempFilterCity" class="options">
            <el-checkbox
              class="checkbox"
              v-for="(city, index) in getCities"
              :key="index"
              :value="city.toLowerCase()"
            >
              {{ city }}
            </el-checkbox>
          </el-checkbox-group>
          <div class="filterButtons">
            <button
              class="filterButton"
              @click="reset('filterCity', 'tempFilterCity', 'showFilterCity')"
            >
              {{ $t('commons.reset') }}
            </button>
            <button
              class="filterButton"
              @click="
                applyFilter('filterCity', 'tempFilterCity', 'showFilterCity')
              "
            >
              {{ $t('commons.apply') }}
            </button>
          </div>
        </div>

        <button class="subFilter" @click="filterState('showFilterDate')">
          {{ $t('newsfeed.dates') }}
          <i
            :class="[
              'arrow',
              { right: !showFilterDate },
              { bottom: showFilterDate },
            ]"
          ></i>
        </button>
        <div v-if="showFilterDate" class="filter">
          <div class="responsiveDate">
            <el-date-picker
              v-model="respDate1"
              :disabled-date="disabledDate"
              type="date"
              :placeholder="$t('newsfeed.from')"
            />
            <el-date-picker
              v-model="respDate2"
              :disabled-date="disableDateTo"
              :min="minDate2"
              type="date"
              :placeholder="$t('newsfeed.to')"
            />
          </div>
          <div class="filterButtons">
            <button
              class="filterButton"
              @click="reset('dateValue', 'dateValue', 'showFilterDate')"
            >
              {{ $t('commons.reset') }}
            </button>
            <button
              class="filterButton"
              @click="applyFilter('dateValue', '', 'showfilterDate')"
            >
              {{ $t('commons.apply') }}
            </button>
          </div>
        </div>

        <button class="subFilter" @click="filterState('showFilterSpirit')">
          {{ $t('newsfeed.spirit_type') }}
          <i
            :class="[
              'arrow',
              { right: !showFilterSpirit },
              { bottom: showFilterSpirit },
            ]"
          ></i>
        </button>
        <div v-if="showFilterSpirit" class="filter">
          <el-checkbox-group class="options" v-model="tempFilterSpirit">
            <el-checkbox
              class="checkbox"
              v-for="(spirit, index) in getSpirit"
              :key="index"
              :value="spirit.toLowerCase()"
            >
              {{ spirit }}
            </el-checkbox>
          </el-checkbox-group>
          <div class="filterButtons">
            <button
              class="filterButton"
              @click="
                reset('filterSpirit', 'tempFilterSpirit', 'showFilterSpirit')
              "
            >
              {{ $t('commons.reset') }}
            </button>
            <button
              class="filterButton"
              @click="
                applyFilter(
                  'filterSpirit',
                  'tempFilterSpirit',
                  'showFilterSpirit'
                )
              "
            >
              {{ $t('commons.apply') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="resetFilterResponsive">
      <button class="resetAllButton" @click="resetFilters">
        {{ $t('newsfeed.reset_filters') }}
      </button>
    </div>

    <div
      v-for="(event, index) in filteredEvents"
      :key="index"
      class="responsivEvent"
    >
      <div>
        <a class="name" :href="event.url" target="_blank">{{ event.name }}</a>
      </div>
      <div class="location">
        <div>{{ event.country }}, {{ event.city }}</div>
      </div>
      <div class="dateOfEvent">
        <div>{{ formatDates(event.dates) }}</div>
      </div>
      <div class="spiritTypeLabel">
        <div>{{ formatSpirit(event.spirit_type_label) }}</div>
      </div>
    </div>
  </div>

  <el-backtop :right="30" :bottom="30" />
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'

export default {
  name: 'newsfeed',
  data() {
    return {
      isComputer: true,
      events: [],
      today: '',
      dateValue: ref(''),
      respDate1: ref(''),
      respDate2: ref(''),
      defaultTime:
        ref <
        [Date, Date] >
        [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)],
      months: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      responsiveFilter: false,
      sortEvent: false,
      tempFilterCountry: [],
      filterCountry: [],
      tempFilterCity: [],
      filterCity: [],
      tempFilterMonth: [],
      filterMonth: [],
      tempFilterSpirit: [],
      filterSpirit: [],
      showFilterCountry: false,
      showFilterEvent: false,
      showFilterDate: false,
      showFilterCity: false,
      showFilterMonth: false,
      showFilterSpirit: false,
      searchCountry: '',
      searchCity: '',
      searchEvent: '',
      SearchIcon: Search,
    }
  },
  mounted() {
    this.today = new Date().toISOString().split('T')[0]
    this.fetchEvents()
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  },
  computed: {
    getEvents() {
      const query = this.searchQuery.toLowerCase()
      let events = this.events
        .map((event) => event.country.toLowerCase())
        .sort()
      events = events.filter((event) => event.toLowerCase().includes(query))
      return [...new Set(events)]
    },
    getCountries() {
      const query = this.searchCountry.toLowerCase()
      let countries = this.filteredEvents
        .map((event) => event.country.toLowerCase())
        .sort()
      countries = countries.map((country) =>
        country === 'usa'
          ? 'USA'
          : country === 'uk'
          ? 'UK'
          : country.charAt(0).toUpperCase() + country.slice(1)
      )
      countries = countries.filter((country) =>
        country.toLowerCase().startsWith(query)
      )
      return [...new Set(countries)]
    },
    getCities() {
      const query = this.searchCity.toLowerCase()
      let cities = this.filteredEvents
        .map((event) => event.city.toLowerCase())
        .sort()
      cities = cities.map(
        (city) => city.charAt(0).toUpperCase() + city.slice(1)
      )
      cities = cities.filter((city) => city.toLowerCase().startsWith(query))
      return [...new Set(cities)]
    },
    getMonths() {
      let months = []
      this.filteredEvents.forEach((event) => {
        let eventMonth = event.month.split('-')
        months.push(...eventMonth)
      })
      months = months
        .map((month) => month.toLowerCase())
        .sort((a, b) => {
          return this.months.indexOf(a) - this.months.indexOf(b.toLowerCase())
        })
      months = months.map(
        (month) => month.charAt(0).toUpperCase() + month.slice(1)
      )
      return [...new Set(months)]
    },
    getSpirit() {
      let types = []
      this.filteredEvents.forEach((event) => {
        let alcohols = event.spirit_type_label.split(';')
        types.push(...alcohols)
      })
      let spirits = types.map((spirit) => spirit.toLowerCase()).sort()
      spirits = spirits.map(
        (spirit) => spirit.charAt(0).toUpperCase() + spirit.slice(1)
      )
      return [...new Set(spirits)]
    },
    filteredEvents() {
      let filteredEvents = this.events

      if (this.filterCountry.length > 0) {
        filteredEvents = filteredEvents.filter((event) =>
          this.filterCountry.includes(event.country.toLowerCase())
        )
      }

      if (this.filterCity.length > 0) {
        filteredEvents = filteredEvents.filter((event) =>
          this.filterCity.includes(event.city.toLowerCase())
        )
      }

      if (this.filterMonth.length > 0) {
        filteredEvents = filteredEvents.filter((event) => {
          let months = event.month.toLowerCase().split('-')
          return this.filterMonth.some((month) =>
            months.includes(month.toLowerCase())
          )
        })
      }

      if (this.filterSpirit.length > 0) {
        filteredEvents = filteredEvents.filter((event) => {
          let types = event.spirit_type_label.toLowerCase().split(';')
          return this.filterSpirit.some((type) =>
            types.includes(type.toLowerCase())
          )
        })
      }

      const query = this.searchEvent.toLowerCase()
      if (query.length > 0) {
        filteredEvents = filteredEvents.filter((event) =>
          event.name.toLowerCase().includes(query)
        )
      }

      if (this.dateValue.length > 1) {
        filteredEvents = filteredEvents.filter((event) => {
          const datesArray = event.dates.split(';')
          const partsDate1 = datesArray[0].split('/')
          const parseDate1 = new Date(
            `${partsDate1[2]}/${partsDate1[1]}/${partsDate1[0]}`
          )
          if (!datesArray[1])
            return (
              parseDate1 >= this.dateValue[0] && parseDate1 <= this.dateValue[1]
            )
          const partsDate2 = datesArray[1].split('/')
          const parseDate2 = new Date(
            `${partsDate2[2]}/${partsDate2[1]}/${partsDate2[0]}`
          )
          return (
            (parseDate1 >= this.dateValue[0] &&
              parseDate1 <= this.dateValue[1]) ||
            (parseDate2 >= this.dateValue[0] &&
              parseDate2 <= this.dateValue[1]) ||
            (parseDate1 <= this.dateValue[0] && parseDate2 >= this.dateValue[1])
          )
        })
      }

      if (this.sortEvent) {
        filteredEvents.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })
      } else {
        filteredEvents.sort(this.comparaisonDate)
      }

      return filteredEvents
    },
  },
  methods: {
    async fetchEvents() {
      await axios
        .get(process.env.VUE_APP_BASE_URL + 'api/events')
        .then((response) => {
          this.events = this.parseDate(response.data.events).sort(
            this.comparaisonDate
          )
          this.filteredEvents
        })
        .catch(() => {})
    },
    checkScreenSize() {
      this.isComputer = window.matchMedia('(min-width: 900px)').matches
    },
    comparaisonDate(a, b) {
      let date1 = a.dates.split(';')[0]
      let date2 = b.dates.split(';')[0]
      const partsDate1 = date1.split('/')
      const parseDate1 = new Date(
        `${partsDate1[2]}/${partsDate1[1]}/${partsDate1[0]}`
      )
      const partsDate2 = date2.split('/')
      const parseDate2 = new Date(
        `${partsDate2[2]}/${partsDate2[1]}/${partsDate2[0]}`
      )
      return parseDate1.getTime() - parseDate2.getTime()
    },
    disabledDate(time) {
      return time.getTime() < Date.now()
    },
    disableDateTo(time) {
      if (this.respDate1) return time.getTime() < this.respDate1.getTime()
      return time.getTime() < Date.now()
    },
    parseDate(events) {
      return events.filter((event) => {
        if (event.dates) {
          const datesArray = event.dates.split(';')

          return datesArray.some((date) => {
            const parts = date.split('/')
            if (!parts[1]) return false
            const parse = new Date(`${parts[2]}/${parts[1]}/${parts[0]}`)
            return parse.toISOString().split('T')[0] >= this.today
          })
        }
        return false
      })
    },
    formatDates(date) {
      const dates = date.split(';')
      if (dates.length > 1) {
        return dates.join('-')
      } else {
        return date
      }
    },
    formatSpirit(spirit) {
      const types = spirit.split(';')
      if (types.length > 1) {
        return types.join('/')
      } else {
        return spirit
      }
    },
    resetFilters() {
      this.tempFilterCity = []
      this.filterCity = []
      this.tempFilterCountry = []
      this.filterCountry = []
      this.tempFilterMonth = []
      this.filterMonth = []
      this.tempFilterSpirit = []
      this.filterSpirit = []
      this.resetShowFilter()
      this.resetSearch()
      this.dateValue = ''
      this.sortEvent = false
      this.responsiveFilter = false
    },
    resetShowFilter() {
      this.showFilterCountry = false
      this.showFilterDate = false
      this.showFilterCity = false
      this.showFilterMonth = false
      this.showFilterSpirit = false
      this.showFilterEvent = false
    },
    resetSearch() {
      this.searchCountry = ''
      this.searchCity = ''
      this.searchEvent = ''
    },
    reset(field, tempFilter, filter) {
      this[field] = []
      this[tempFilter] = []
      if (field === 'searchEvent') this.sortEvent = false
      if (field === 'dateValue') {
        this.respDate1 = ''
        this.respDate2 = ''
      }
      this.unshow(filter)
      this.resetSearch()
    },
    unshow(field) {
      this[field] = false
    },
    show(field) {
      this.resetShowFilter()
      this[field] = true
    },
    filterState(field) {
      if (this[field]) this.unshow(field)
      else this.show(field)
    },
    applyFilter(data, tempFilter, filter) {
      if (data == 'dateValue') {
        if (this.respDate1 || this.respDate2) {
          if (!this.respDate1) this.respDate1 = this.respDate2
          if (!this.respDate2) this.respDate2 = this.respDate1
          this[data] = [this.respDate1, this.respDate2]
        }
      } else this[data] = this[tempFilter].slice()
      this.unshow(filter)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

* {
  font-family: 'Inter';
}

.table {
  min-height: 50vh;
  width: 90vw;
}

.table-rounded {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 7px;
}

table {
  color: white;
  width: 100%;
  margin: 2vh 0;
  font-family: Arial, sans-serif;

}

.events-options-infos {
  display: flex;
  align-items: flex-end;

  p {
    margin-left: 8px;
  }
}

th,
td {
  padding: 8px;
  text-align: center;
}

th:first-of-type {
  border-radius: 10px 0 0 0;
}

th:last-of-type {
  border-radius: 0 10px 0 0;
}

th {
  color: black !important;
  button {
        color:#9fb7ec ;
  }
}

tr:hover {
  background-color: #606060 !important;
}

tr:nth-child(odd) {
  background-color: #444444;
}

tr:nth-child(even),
thead {
  background-color: #333333;
}

.arrow {
  border: solid rgb(177, 177, 206);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 2px;
  color: $base-color !important;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.bottom {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.filterButtons {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #2667ff;
  font-weight: bold;
}

a:focus {
  outline: none;
}

.checkbox {
  padding: 5px 0;
}

.filter {
  color: white;
  position: absolute;
  margin-top: 2px;
  z-index: 5;
  padding: 10px;
  max-height: 26vh;
  overflow: auto;
  background-color: black;
  border-radius: 5px;
  box-shadow: inset;
  display: flex;
  flex-direction: column;
}

.checkbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.675);
}

.options {
  display: flex;
  flex-direction: column;
  height: 80%;
  margin: 1vh 0;
  overflow: auto;
}

input {
  color: black;
}

button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: rgb(34, 34, 34, 0.3);
  color: rgb(177, 177, 206);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: max-content;
}

button:hover {
  transform: translateY(-1px);
}

button:active {
  transform: translateY(1px);
}

.resetAllButton {
  background-color: #9fb7ec;
  color: rgb(34, 34, 34);
  max-width: 200px;
}

.filterButton {
  margin: 0 5px;
}

@media screen and (max-width: 900px) {
  .responsive {
    width: 95%;
    margin-top: 2vh;
  }

  .responsivEvent {
    width: 100%;
    min-height: 5vh;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgb(68, 67, 67);
    margin-bottom: 2px;
    display: block;
    text-align: center;
  }

  .responsivEvent .name {
    color: rgb(177, 177, 206);
    font-size: 20px;
  }

  .location,
  .dateOfEvent,
  .spiritTypeLabel {
    color: white;
  }

  .eventNameSearch,
  .eventFilterResponsive,
  .resetFilterResponsive {
    margin-bottom: 1vh;
  }

  .eventFilterResponsive button,
  .resetFilterResponsive button {
    background-color: #606060;
    color: white;
    width: 100%;
  }

  button {
    border-radius: 0%;
    background-color: white;
    color: black;
  }

  .filter {
    position: relative;
    padding: 10px;
    max-height: 30vh;
    overflow: auto;
    background-color: black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }

  .subFilter {
    background-color: #353535 !important;
    color: rgb(177, 177, 206) !important;
  }

  .responsiveDate {
    display: flex;
    justify-content: space-between;
  }
}
</style>
